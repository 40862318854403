var fullShinyObj;

// TODO-future; It would be nice to have a way to export this type value instead of / in addition to `Shiny`

function setShinyObj(shiny) {
  fullShinyObj = shiny;
}
function validateShinyHasBeenSet() {
  if (typeof fullShinyObj === "undefined") {
    throw "Shiny has not finish initialization yet. Please wait for the 'shiny-initialized' event.";
  }
  return fullShinyObj;
}

//// 2021/03: TypeScript Conversion note
// These methods are here due to the delayed initialization of `Shiny.shinyapp`. I
// In theory, there could be multiple instances of `shinyapp`. In practice (and implementation), this is not possible and is a 1:1 coupling with `window.Shiny`.
// To avoid calls to a large Shiny object, helper methods are created to wrap around calling the fully instantiated window.Shiny value.
// TODO-barret; Why is `initShiny()` delayed? Is this to allow users to shim in some code? Why can't it be defined in the init method (maybe w/ an extra trigger call?)
function shinySetInputValue(name, value, opts) {
  validateShinyHasBeenSet().setInputValue(name, value, opts);
}
function shinyShinyApp() {
  return validateShinyHasBeenSet().shinyapp;
}
function setShinyUser(user) {
  validateShinyHasBeenSet().user = user;
}
function shinyForgetLastInputValue(name) {
  validateShinyHasBeenSet().forgetLastInputValue(name);
}
function shinyBindAll(scope) {
  validateShinyHasBeenSet().bindAll(scope);
}
function shinyUnbindAll(scope) {
  var includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  validateShinyHasBeenSet().unbindAll(scope, includeSelf);
}
function shinyInitializeInputs(scope) {
  validateShinyHasBeenSet().initializeInputs(scope);
}
function shinyAppBindOutput(id, binding) {
  shinyShinyApp().bindOutput(id, binding);
}
function shinyAppUnbindOutput(id, binding) {
  return shinyShinyApp().unbindOutput(id, binding);
}
function getShinyOnCustomMessage() {
  return validateShinyHasBeenSet().oncustommessage;
}
var fileInputBinding;
function getFileInputBinding() {
  return fileInputBinding;
}
function setFileInputBinding(fileInputBinding_) {
  fileInputBinding = fileInputBinding_;
}
function getShinyCreateWebsocket() {
  return validateShinyHasBeenSet().createSocket;
}
export { setShinyObj, shinySetInputValue, shinyShinyApp, setShinyUser, shinyForgetLastInputValue, shinyBindAll, shinyUnbindAll, shinyInitializeInputs, shinyAppBindOutput, shinyAppUnbindOutput, getShinyOnCustomMessage, getFileInputBinding, setFileInputBinding, getShinyCreateWebsocket };